import {Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Output, Input} from '@angular/core';
import {MapsAPILoader, MouseEvent} from '@agm/core';
import {error} from 'protractor';
import { languageConstants } from 'src/app/constants/languageConstants';

interface Driver {
  lat: number;
  lng: number;
  label: string;
  draggable: boolean;
}

@Component({
  selector: 'google-location-selector',
  templateUrl: './google-location-selector.html',
  styleUrls: ['./google-location-selector.css']
})


export class GoogleLocationSelectorComponent implements OnInit {

  title = 'AGM project';

  latitude = 24.4667;

  longitude = 54.4667;

  zoom = 12;

  address: string;

  private geoCoder;
  language = 'en';
  languageConstants = languageConstants;
  languageKeys = languageConstants[this.language];




  @ViewChild('search', {static: false}) searchElementRef: ElementRef;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Input() drivers: Driver[] = [];

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    const langu = localStorage.getItem('language');
    if(langu) {
      this.language = langu;
      this.languageKeys = this.languageConstants[langu]
    }
  }


  ngOnInit() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.getAddress(this.latitude, this.longitude);
          console.log(this.searchElementRef.nativeElement.value)
        });
      });
    });
  }

  // Get Current Location Coordinates
  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.getAddress(this.latitude, this.longitude);
      }, (error) => {
        console.log('Location error : ', error);
      });
    } else {
      console.log('Failed load location . . .');
    }
  }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }
   getCountry(addrComponents) {
     // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < addrComponents.length; i++) {
      if (addrComponents[i].types[0] === 'country') {
        return addrComponents[i].short_name;
      }
      if (addrComponents[i].types.length === 2) {
        if (addrComponents[i].types[0] === 'political') {
          return addrComponents[i].short_name;
        }
      }
    }
    return false;
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          this.invokeEvent({
            location: {lat: latitude, lng: longitude},
            address: this.address,
            fullAddress: results[0].address_components,
            country: this.getCountry(results[0].address_components)
          });
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  resetLocation(){
    this.setCurrentLocation();
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
}
