export const LANGUAGES = [
  {label: 'English', value: 'en'},
  {label: 'Arabic', value: 'ar'},
  {label: 'Malyalam', value: 'ml'},
  // {label: 'Urdu', value: 'ur'}
];
export const VEHICLES = [
  {label: 'Vehicle 1', value: '22'},
  {label: 'Vehicle 2', value: '22'},
  {label: 'Vehicle 3', value: '22'},
  {label: 'Vehicle 4', value: '22'},
  {label: 'Vehicle 5', value: '22'},
];
export const COMMODITY = [
  {label: 'Commodity 1', value: '9'},
  {label: 'Commodity 2', value: '9'},
  {label: 'Commodity 3', value: '9'},
  {label: 'Commodity 4', value: '9'},
  {label: 'Commodity 5', value: '9'}
];
export const TRIP_HOURS = [
  {label: '1 Hour/Day', value: '  1'},
  {label: '2 Hour/Day', value: '2'},
  {label: '3 Hour/Day', value: '3'},
  {label: '4 Hour/Day', value: '4'},
  {label: '5 Hour/Day', value: '5'},
  {label: '6 Hour/Day', value: '6'},
  {label: '7 Hour/Day', value: '7'},
  {label: '8 Hour/Day', value: '8'},
  {label: '9 Hour/Day', value: '9'},
  {label: '10 Hour/Day', value: '10'},
  {label: '11 Hour/Day', value: '11'},
  {label: '12 Hour/Day', value: '12'},
  {label: '13 Hour/Day', value: '13'},
  {label: '14 Hour/Day', value: '14'},
  {label: '15 Hour/Day', value: '15'},
  {label: '16 Hour/Day', value: '16'},
  {label: '17 Hour/Day', value: '17'},
  {label: '18 Hour/Day', value: '18'},
  {label: '19 Hour/Day', value: '19'},
  {label: '20 Hour/Day', value: '20'},
  {label: '21 Hour/Day', value: '21'},
  {label: '22 Hour/Day', value: '22'},
  {label: '23 Hour/Day', value: '23'},
];
export const DELIVERY_WEIGHTS = [
  {label: 'Weight 1-5Kg', value: '1', length: 5, width: 5, height: 5},
  {label: 'Weight 5-20Kg', value: '2', length: 20, width: 20, height: 20},
  {label: 'Weight 20-50Kg', value: '3', length: 50, width: 50, height: 50},
];
export const DELIVERY_ADDRESS_TYPE = {
  en: [
    {label: 'Home', value: 'home'},
    {label: 'Office', value: 'office'}],
  ar: [
    {label: 'الصفحة الرئيسية', value: 'home'},
    {label: 'مكتب. مقر. مركز', value: 'office'}
  ],
  ur: [
    {label: 'گھر', value: 'home'},
    {label: 'دفتر', value: 'office'}
  ]
};
export const OTHER_ADDRESS_TYPE = {
  en: [
    {label: 'Residential Building', value: 'residential_building'},
    {label: 'Commercial Building', value: 'commercial_building'},
    {label: 'Project Site', value: 'project_site'},
    {label: 'Other', value: 'others'}],
  ar: [
    {label: 'مبنى سكني', value: 'residential_building'},
    {label: 'مبنى تجاري', value: 'commercial_building'},
    {label: 'موقع المشروع', value: 'project_site'},
    {label: 'الآخرين', value: 'others'}
  ],
  ml: [
    {label: 'താമസിക്കാനുള്ള കെട്ടിടം', value: 'residential_building'},
    {label: 'വാണിജ്യ കെട്ടിടം', value: 'commercial_building'},
    {label: 'പ്രോജക്റ്റ് സൈറ്റ്', value: 'project_site'},
    {label: 'മറ്റുള്ളവ', value: 'others'}
  ]
};
const vehicleCount = {
  en: [
    {label: '1 Vehicle', value: 1},
    {label: '2 Vehicles', value: 2},
    {label: '3 Vehicles', value: 3},
    {label: '4 Vehicles', value: 4},
    {label: '5 Vehicles', value: 5}
  ],
  ar: [
    {label: 'سيارة واحدة', value: 1},
    {label: '2 مركبة', value: 2},
    {label: '3 مركبة', value: 3},
    {label: '4 مركبn', value: 4},
    {label: '5 مركبة', value: 5}
  ],
  ur: [
    {label: '1 گاڑی', value: 1},
    {label: '2 گاڑیاں', value: 2},
    {label: '3 گاڑیاں', value: 3},
    {label: '4 گاڑیاں', value: 4},
    {label: '5 گاڑیاں', value: 5}
  ]
};
export const counts = vehicleCount[localStorage.getItem('language')];
export const SERVICE_TYPE = [
  {label: 'TRANSPORT', value: 1},
  {label: 'RENTAL', value: 2},
  {label: 'DELIVERY', value: 3}
];
export const CATEGORY = {
  1: [{label: 'PRIVATE', value: '1'}, {label: 'PUBLIC', value: '2'}, {label: 'GOODS ', value: '3'}],
  2: [{label: 'Heavy', value: '1'}, {label: 'Goods', value: '2'}, {label: 'Others', value: '3'}],
  3: [{label: '0-5', value: '1'}, {label: '5-20', value: '2'}, {label: '20-50', value: '3'}]
};

export const POST_ENQUIRY = {ar: 'قدم طلبك هنا', en: 'POST ENQUIRY', ml: 'പോസ്റ്റ് അന്വേഷണം'};

