import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { languageConstants } from 'src/app/constants/languageConstants';

@Component({
  selector: 'logibids-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  dropdown: boolean;
  lan: any;
  privacy: boolean;
  language = 'en';
  languageConstants = languageConstants;
  languageKeys = languageConstants[this.language];

  
  constructor(
    private messageService: MessageService,
    private router: Router
  ) {
    const langu = localStorage.getItem('language');
    if(langu) {
      this.language = langu;
      this.languageKeys = this.languageConstants[langu]
    }
   }

  ngOnInit() {
  }

  addSingle() {
    this.messageService.add({ severity: 'success', summary: 'Service Message', detail: 'Via MessageService' });
  }
  gotoLogin() {
    this.router.navigate(['/login']);
  }
  showNav() {
    this.dropdown = !this.dropdown;
    this.privacy = false;
  }

  setLanguage(language) {
    const url = document.URL.split('/');
    url[3] = language;
    window.location.href = url.join('/');
  }

  showPrivacy() {
    this.privacy = !this.privacy;
    this.dropdown = false;
  }

  gotoPrivacy() {
    this.router.navigate(['/public/privacy']);
  }

  gotoTerms() {
    this.router.navigate(['/public/terms']);
  }
  gotoFaq() {
    this.router.navigate(['/public/faq']);
  }
  gotoContact() {
    this.router.navigate(['/public/contact']);
  }
  gotToFaq() {
    this.router.navigate(['/public/faq']);
  }
}
