export const languageConstants = {
  en: {
    KMSDAY: 'Kms / Day',
    HRSDAY: 'Hrs / Day',
    NOTSTARTED: 'Not Started',
    MORE: "More",
    CLIENT: "Client",
  BIDTIMEOVER: 'Bid time is over',
  BRAND: 'Type of Brand',
  MODEL: 'Model',
  TIMEMESSAGE: 'Delivery Time Message',
  TYPEOFDELIVERY: 'Type of Delivery',
  IMMEDIATE: 'Immediate',
  SCHEDULE: 'Schedule Ride',
  ImmediateDeliveryChoose: 'Please choose immediate delivery because your schedule time less than one hour.',
  ADDRESSTYPE: 'Address Type',
  AREA: 'Area',
  APARTMENTNO: 'Apartment No.',
  DELIVERAVENUE: 'Delivery Avenue',
  AVENUE: 'Avenue',
  BUILDINGNO: 'Building No',
  ADDITIONALINFO: 'Additional Info',
  RS: "Rating Successful",
  SOMETHINGWENTWRONG: "Oops something went wrong . . .",
  LOADING: "Loading",
  SEARCHIN: "Search in",
  YOURPHONENUMBER: "Your Phone Number",
  CHOOSEYOURLOCATION: "Choose your location",
  DIDNOTRECIEVEOTP: "Didn't receive OTP ?",
  TOTALKM: "Total Km",
  HOWWOULDYOURATETHEOVERALLQUALITYOFTRIP: "How would you rate the overall quality of this trip ?",
  AUCTIONENDPOINT: "Auction end time",
  NOTRIPSFOUND: "NO Trips Found",
  VIEW: "View",
  STARTTIME: "Start time",
  SELECTVEHICLE: "Select Vehicle",
  SELECTVEHICLESPEC: "Model",
  SELECTBRAND: "Select Brand",
  PLEASESELECTSUBCATEGORY: "Please select subcategory",
  PLEASESELECTVEHICLESPEC: "Model",
  PLEASESELECTVEHICLE: "Please Select a Vehicle",
  ENTERAMOUNT: "Enter Amount",
  POSTENQUIRYY: "Post Enquiry",
  POSTENQUIRY: "POST ENQUIRY",
  MYENQUIRIES: "  My Enquiries",
  TRANSPORTGOODS: "TRANSPORT GOODS",
  ENQUIRY: "ENQUIRY",
  REVIEW: "REVIEW",
  PLEASEADDCOMMENT: "Please Add Your Comment",
  PLEASEADDREVIEW: 'Please Add Your Review',
  TRANSPORTPUBLIC: "TRANSPORT PUBLIC",
  COMMODITY: "Type of commodity",
  TRIPS: "Trips",
  ASSISTANCE: "Assistance",
  INSURANCE: "Insurance",
  DRIVERRATING: "Driver Rating",
  SPRATING: "Service Provider Rating",
  BID: "Bid",
  LOWESTBID: "Lowest Bid",
  HIGHESTBID: "Highest Bid",
  LOCATION: "Location",
  EDIT: "Edit",
  EDITPROFILE: "Edit Profile",
  MYPROFILE: "My Profile",
  CONFIRMBID: "Confirm bid",
  ENQUIRYCHECKOUTPAGE: "Enquiry Checkout Page",
  DELETE: "Delete",
  CONFIRM: "Confirm",
  ADDITIONALINFORMATION: "Additional Information",
  PAYDIRECTLYTOSERVICEPROVIDER: "Pay Directly To Service Provider",
  TOTALAMOUNT: "Total Amount",
  SERVICECHARGE: "Logibids Service Charge",
  CHOOSEPAYMENTMETHOD: "Choose your Payment Method",
  MINUTES: "Mins",
  HR: "hr",
  WIDTH: "Width",
  PLEASESELECTCOMMODITY: "Please Select Commodity",
  SELECTCOMMODITY: "Select Commodity",
  LENGTH: "Length",
  HEIGHT: "Height",
  ENTERCODE: "Enter Code",
  NONOTIFICATIONAVAILABLE: "No Notification Available",
  PRIVACYPOLICY: "Privacy Policy",
  TERMSANDCONDITIONS: "Terms And Conditions",
  DELETEACCOUNT: "Delete Account",
  LOGOUT: "Logout",
  MYWALLET: "My Wallet",
  MyTRIP: "My Trip",
  MYRATING: 'My Rating',
  ENTERYOURPASSWORD: "Enter Your Password",
  CONFIRMPASSWORD: "Confirm Password",
  newconfirmrequired: "Please enter new password and confirm password",
  oldconfirmrequired: "Please enter old password and confirm password",
  oldnewPassword: "Please enter old password and new password",
  OLDPASSWORDNEWPASSWORD: "Old password & new password can't be same",
  LOGIN: "Login",
  CONFIRMPICKUP: "Confirm Pickup",
  SETYOURLOCATION: "Set your Location",
  LANGUAGE: "Language",
  GETSTARTED: "Get Started",
  ENTERPHONENUMBER: "Enter your phone number",
  ENTEROLDPASSWORD: "Enter Old Password",
  ENTERNEWPASSWORD: "Enter New Password",
  ENTERNEWPASSWORDMINLENGTH: "New Password can't be less than 4 characters",
  ENTERCONFIRMPASSWORD: 'Enter Confirm Password',
  ENTERCODENEWPASSWORD: "Enter Your Code And New Password",
  FORGOTPASSWORD: "Forgot Password",
  COPYRIGHT: "Copyright © 2010-2020 (Transport Service)",
  NEXT: "Next",
  WALLETBALANCE: "Wallet Balance",
  ALLBRAND: "All Brands",
  BIDNOW: "Bid Now",
  TRANSPORT: "Transport",
  VEHICLEREQUIRED: "Vehicle is required",
  RENTAL: "Rental",
  RENTALOTHERS: "RENTAL OTHERS",
  WITHDRAW: "Withdraw",
  YOURWALLETBALANCE: "Your Wallet Balance is",
  DELIVERY: "Delivery",
  ADD: "Add",
  UPLOADYOURID: "Upload Your ID",
  NOOFTRIPS: "No of Trips",
  DOCUMENTS: "Documents",
  UPLOADCOMPANYLICENSE: "Upload Company License",
  ACCEPTTERMSANDCONDITION: "Accept Terms and Conditions",
  SUBMIT: "Submit",
  FUEL: "Fuel",
  REJECTTRIP: "Reject Trip",
  PICKUP: "Pickup",
  DELIVER: "Deliver",
  CALL: "Call",
  ACCEPTTRIP: "Accept Trip",
  SHOWMAP: "Show Map",
  DRIVER: "Driver",
  SERVICE: "Maintenance",
  DAYS: " Days",
  HOURSSLASHDAY: " Hours/Day",
  HOURS: "Hours",
  HOURSPERDAY: "Hours per Day",
  SCHEDULETIME: "Time Schedule",
  SCHEDULERIDE: "Schedule Ride",
  BIDSTARTTIME: "Bid Start Time",
  BIDENDTIME: "Bid End Time",
  PICKUPLOCATION: "Start Location Address",
  NOOFDAYS: "No of Days",
  KMSPERDAY: "Kms per Day",
  CREATEBID: "Create Bid",
  ENQUIRYENDS: "ENQUIRY ENDS",
  NAME: "Name",
  TO: "To",
  CONTACT: "Contact",
  EMAIL: "Email",
  CUSTOMERRATING: "Customer Rating",
  BIDFOR: "BID FOR",
  FROM: "FROM",
  YOU: "YOU",
  GOTOTRIP: "Go to Trip",
  USERDETAILS: "User Details",
  DRIVERDETAILS: "Driver Details",
  WEIGHTOFCOMMODITY: "Weight of Commodity",
  DELIVERYCHARGE: "Delivery Charge",
  TOTALDISTANCE: "Total Distance",
  PICKUPSCHEDULED: "Scheduled Time",
  TRACKDRIVER: "Track Driver",
  TYPEOFCOMMODITY: "Type of Commodity",
  REMAININGTIMEFORPICKUP: "REMAINING TIME",
  ADDBIDAMOUNT: "Add Bid Amount",
  ENQUIRYDETAILPAGE: "Enquiry Detail Page",
  TYPEOFVEHICLE: "Type of Vehicle",
  HOME: "HOME",
  ENQUIRYID: "Enquiry ID",
  ADDDRIVER: "Add Driver",
  ADDVEHICLE: "Add Vehicle",
  COMPLETEYOURPROFILE: "Complete Your Profile",
  NOACTIVEBID: "There Is No Active Bid For You",
  YOURLASTBID: "Your last bid",
  SEARCHENQUIRY: "Search Enquiry",
  NORECORDSFOUND: "No Records Found",
  AUCTIONS: "Auctions",
  RUNNING: "RUNNING",
  PENDING: "PENDING",
  ENDED: "ENDED",
  CANCEL: "Cancel",
  CANCELED: "CANCELED",
  CANCELLED: "CANCELLED",
  CANCELLEDENQUIRY: "Cancelled Enquiry",
  BIDDING_STARTED: "BIDDING STARTED",
  HALF_TIME: "HALF TIME OVER",
  ENDING_SOON: "ENDING SOON",
  BIDS: "BIDS",
  login_error_1: "Please provide valid details !",
  Required: "*Required",
  Invalid: "*Invalid",
  Invalid_PhnNumber: "* Valid Phone Number Required",
  password_character:"* The password must be at least 8 characters, 1 lowercase ,1 uppercase ,1 number, 1 special characters",
  driver_message: "Are you sure that you want to delete this driver?",
  acpt_lbl: "yes",
  rjct_lbl: "no",
  passwword_must: "  * Passwords must match",
  forgot_pwd_msg: "Please Enter Valid Phone Number !",
  transport_good_detail: "Are you sure that you want to delete this enquiry?",
  account_message: "Are you sure that you want to delete your account ?",
  bid_closed: "Bid Closed",
  enq_closed: "Enquiry has been closed !",
  enq_reject: "Enquiry has been rejected !",
  signup_success: "Signup Successfull",
  confirm: "Please wait 5 seconds for verification!",
  verification: "Verification Successfull",
  otp: "OTP sent successfully",
  wrong: "Something went wrong",
  upload: "Upload Document",
  profile_updated: "Profile Updated !",
  pr_pwd_updated: "Password Updated !",
  enq_success: "Enquiry Posted successfully",
  enq_failed: "Failed to submit enquiry",
  pay_success: "Payment Success",
  pay_error: "Payment Failed",
  enq_confirm: "Enquiry has been Confirmed !",
  updated_pwd: "Succesfully Updated Password",
  oops: "Oops",
  Driver: "Driver",
  DELIVERYLOCATION: "End Destination Address",
  OK: "OK",
  ADDMORE: "Add Money",
  TRIPDISTANCE: "Trip Distance",
  ACCEPTED: "ACCEPTED",
  PAY: "Pay",
  NETBANKING: "Net Banking",
  CREDITDEBITCARD: "Credit / Debit Cards",
  WALLET: "Wallet",
  HESABE: "Hesabe",
  PAYMENTFAILED: "Payment Failed",
  PROCESSINGCOMPLETE: "Processing Completed",
  GOHOME: "Go Home",
  PAYMENT: "Payment",
  REGESTRATIONFAILED: "Registration Failed",
  SCHEDULEDSTART: "Schedule Start",
  SELECTCATEGORY: "Select Category",
  LIGHTTRUCK: "Light Truck",
  MINITRUCK: "Mini Truck",
  EXAVATOR: "Excavator",
  PAYMENTSUCCESFULL: "Payment Successfull",
  SELECTPICKUPLOCATION: "Select Start Location Address",
  SELECTDELIVERYLOCATION: "Select Delivery Location Address",
  SELECTHOURSPERDAY: "Select hours per day",
  SELECTRIPS: "Select Trips",
  CAR: "Car",
  BUS: "Bus",
  TRAILOR: "Trailor",
  CONTACTUS: "Contact US",
  TIME: "Time",
  USERTYPE: "User Type",
  SIGNUP: "Signup",
  YOURACCOUNT: "Your Account",
  DONTRECIEVE: "Don't receive",
  AGREECONTINUE: "Agree & Continue",
  CHANGEPASSWORD: "Change Password",
  passwordstr10: 'Your password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number digit, and one special character.',
  LOGIBIDSDEPOSITAMOUT: "Logibids Deposit Amount",
  passwordstr1: "1) Your password must contain at least 8 characters.",
  passwordstr2: "2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)",
  passwordstr3: "3) Your password must contain at least one lowercase letter.",
  passwordstr4: "4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)",
  passwordstr5: "5) Your password must contain at least one special character -for example: $, #, @, !,%,^,&,*,(,)",
  NOVEHICLEFOUND: "No Vehicles Found",
  RENTALS: "Rentals",
  YOURNAME: "Your Name",
  YOUREMAIL: "Your Email (optional)",
  CHOOSEYOURSERVICES: "Choose Your Services",
  IMMEDIATEDELIVERY: "Immediate Delivery",
  SCHEDULEDDELIVERY: "Scheduled Delivery",
  APPARTMENTNUMBER: "Apartment Number",
  BUILDINGNUMBER: "Building Number",
  STREET: "Street",
  BLOCK: "Block",
  STATE: "State",
  PLEASESELECTADDRESSTYPE: "Please Select addressType",
  SEARCHNEARESTLOCATION: "Search Nearest Location",
  OLDPASSWORD: "Old Password",
  NEWPASSWORD: "New Password",
  YOUREMAILL: "Your Email",
  LOGIBIDSONFACEBOOK: "Logibids on Facebook",
  LOGIBIDSONINSTAGRAM: "Logibids on Instagram",
  LOGIBIDSONX: "Logibids on Twitter",
  NORESULTSFOUND: "No Results Found",
  ADDMONEY: "Add Money",
  GOODS: "Goods",
  PUBLICTRANSPORT: "Public Transport",
  GOODSTRANSPORTRENTALS: "Goods Transport Rental",
  HEAVYEQUIPEMENTS: "Heavy Equipments",
  OTHERS: "Others",
  PAIDFOR: "PAID FOR",
  ADDEDMONEYTOWALLET: "ADDED MONEY TO WALLET",
  CONTACTUSS: "Contact",
  SAVE: "Save",
  HOURSS: 'Hrs',
  DAY: "Day",
  AIRPORT: "Airport",
  SELECTWEIGHT: "Select Weight",
  SCHEDULEDEND: "Schedule End",
  AUCTIONSTART: "Auction Start",
  AUCTIONEND: "Auction End",
  TRIPCOUNT: "Trip Count",
  AUCTIONENDTIME: "Auction End Time",
  REQUIRED: "Required",
  LOGIBIDSSERVICECHARGE: "Logibids Service Charge",
  CHOOSEDIFFERENTDELIVERYLOCATION: "Choose Different Delivery Location",
  PLEASEPROVIDEAPPROPRIATEINFORMATION: "Please provide appropriate / required information",
  INFOWINDOWCONTENT: "InfoWindow content",
  CONFIRMATION: "Confirmation",
  PLEASEWAITREDIRECTEDINFIVESECONDS: "Please wait this will redirected in 5 seconds",
  SIGNUPSUCCESFULL: "Signup Successfull",
  delete_bid: "Enquiry delete successfully",
  withdraw_suff_amount_msg: "You have insufficent amount",
  signIn: {
  phoneRequired: "Please enter mobile number",
  phoneValid: "Please enter valid mobile number",
  passwordRequired: "Please enter password",
  passwordMinLength: "Password can't be less than 4 characters",
  },
  password_must: "Password must match",
  otp_required: "OTP is required",
  editProfile: {
  email_invalid: "Please enter valid email Id",
  },
  noti_label: "Notification",
  tripStatus: {
  readyMessage: "Driver is ready for pickup.",
  startMessage: "Driver has started trip.",
  endMessage: "Driver has ended trip.",
  },
  KMSDAYS: 'Kms/Days',
  FAQs: 'FAQs'
  },
  ar: {
    KMSPERDAY: 'كم / يوم',
    KMSDAY: 'كم / يوم',
    HRSDAY: 'ساعة / يوم',
    NOTSTARTED: 'لم تبدأ',
    MORE: 'أكثر',
    CLIENT: 'عميل',
  BIDTIMEOVER: 'انتهى وقت المزايدة',
  BRAND: 'نوع العلامة التجارية',
  MODEL: 'موديل',
  TIMEMESSAGE: 'رسالة وقت التسليم',
  TYPEOFDELIVERY: 'نوع التوصيل',
  IMMEDIATE: 'فورى',
  SCHEDULE: 'وقت الرحلة',
  ImmediateDeliveryChoose: 'برجاء اختيار التسليم الفوري لأن وقت جدولك أقل من ساعة واحدة.',
  ADDRESSTYPE: 'نوع العنوان',
  AREA: 'منطقة',
  APARTMENTNO: 'رقم الشقة',
  DELIVERAVENUE: 'شارع التسليم',
  AVENUE: 'شارع',
  BUILDINGNO: 'رقم المبنى',
  ADDITIONALINFO: 'معلومات إضافية',
  RS: "التقييم ناجح",
  SOMETHINGWENTWRONG: "تبا شيء ما حدث بشكل خاطئ",
  LOADING: "جار التحميل",
  SEARCHIN: "بحث في",
  YOURPHONENUMBER: "اختر موقعك",
  CHOOSEYOURLOCATION: "اختر موقعك",
  DIDNOTRECIEVEOTP: "لم يتم تسليم رقم التحقق؟",
  SIGNUPSUCCESFULL: "الاشتراك بنجاح",
  TOTALKM: "إجمالي كم",
  CONFIRMATION: "تأكيد",
  INFOWINDOWCONTENT: "محتوى نافذة المعلومات",
  PLEASEPROVIDEAPPROPRIATEINFORMATION: "*يرجى تقديم المعلومات المطلوبه",
  CHOOSEDIFFERENTDELIVERYLOCATION: "*اختر موقع توصيل مختلف",
  SELECTRIPS: "اختر الرحله",
  LOGIBIDSSERVICECHARGE: 'رسوم خدمة لوجيبدز',
  NOOFDAYS: "عدد الأيام",
  REQUIRED: "مطلوب",
  AUCTIONENDTIME: "وقت انتهاء المزايدة",
  TRIPCOUNT: "عدد الرحلات ",
  AUCTIONEND: "نهاية المزايدة",
  AUCTIONSTART: "بداية المزايدة",
  SCHEDULEDEND: "حدد موعد النهاية",
  SCHEDULEDSTART: "حدد موعد البداية",
  SELECTWEIGHT: "حدد الوزن",
  AIRPORT: "مطار",
  CONFIRMPICKUP: "تأكيد الاستلام",
  HOURSS: "ساعات",
  DAY: 'يوم',
  CANCELLEDENQUIRY: "الغاء الطلب",
  SAVE: "حفظ",
  CONTACTUSS: "اتصل بنا",
  ADDEDMONEYTOWALLET: "تمت إضافة الأموال إلى المحفظة",
  PAIDFOR: "مدفوع لأجل",
  OTHERS: "اخرى",
  HEAVYEQUIPEMENTS: "معدات ثقيلة",
  GOODSTRANSPORTRENTALS: "تأجير نقل البضائع",
  PUBLICTRANSPORT: "النقل العام",
  GOODS: "البضائع",
  ADDMONEY: "إضافة المال",
  NORESULTSFOUND: "لم يتم العثور على نتائج",
  LOGIBIDSONINSTAGRAM: "لوجيبدز على انسنجرام",
  LOGIBIDSONFACEBOOK: "لوجيبدز على الفيسبوك",
  LOGIBIDSONX: "لوجيبدز على تويتر",
  YOUREMAILL: "بريدك الإلكتروني (اختياري)",
  NEWPASSWORD: "كلمة المرور الجديدة",
  OLDPASSWORD: "كلمة المرور القديمة",
  PLEASEADDCOMMENT: "الرجاء إضافة تعليقك",
  REVIEW: 'مراجعة',
  PLEASEADDREVIEW: 'الرجاء إضافة رأيك',
  SEARCHNEARESTLOCATION: "البحث عن موقع قريب",
  PLEASESELECTADDRESSTYPE: "يرجى تحديد العنوان",
  STATE: "دولة",
  BLOCK: "قطعة",
  STREET: "شارع",
  BUILDINGNUMBER: "رقم المبنى (اختياري)",
  APPARTMENTNUMBER: "رقم الشقة (اختياري)",
  ADDITIONALINFORMATION: "معلومات إضافية (اختياري)",
  ENQUIRYID: "رقم الطلب",
  HOURSSLASHDAY: "ساعة /يوم",
  SERVICE: "صيانة",
  FUEL: "وقود",
  DRIVER: "سائق",
  CONFIRMPASSWORD: "تأكيد كلمة المرور",
  newconfirmrequired: "الرجاء إدخال كلمة المرور الجديدة وتأكيد كلمة المرور",
  oldconfirmrequired: "الرجاء إدخال كلمة المرور القديمة وتأكيد كلمة المرور",
  oldnewPassword: "الرجاء إدخال كلمة المرور القديمة وكلمة المرور الجديدة",
  OLDPASSWORDNEWPASSWORD: "لا يمكن أن تكون كلمة المرور القديمة وكلمة المرور الجديدة متماثلتين",
  YOURNAME: "الاسم",
  YOUREMAIL: "بريدك الإلكتروني (اختياري)",
  SCHEDULEDDELIVERY: "تم تحديد موعد التسليم",
  IMMEDIATEDELIVERY: "توصيل سريع",
  PLEASESELECTSUBCATEGORY: "الرجاء تحديد الفئة الفرعية",
  SELECTVEHICLESPEC: "اختر مواصفات المركبه",
  PLEASESELECTVEHICLESPEC: 'اختر مواصفات المركبه',
  STARTTIME: "وقت البدء",
  CHANGEPASSWORD: "تغيير كلمة المرور",
  passwordstr10: 'يجب أن تتكون كلمة المرور الخاصة بك من 8 أحرف على الأقل وتتضمن حرفًا كبيرًا واحدًا وحرفًا صغيرًا واحدًا ورقمًا واحدًا وحرفًا خاصًا واحدًا على الأقل.',
  AGREECONTINUE: "موافقة ومتابعة",
  DONTRECIEVE: "لم تتلقى ",
  YOURACCOUNT: "الحساب الخاص بك",
  SIGNUP: " تسجيل جديد",
  CHOOSEYOURSERVICES: "اختر خدمتك",
  RENTALS: "الإيجارات",
  NOVEHICLEFOUND: "لم يتم العثور على مركبات",
  EDIT: "تعديل",
  USERTYPE: "نوع المستخدم",
  MYPROFILE: "ملفى",
  EDITPROFILE: "تعديل الملف",
  TIME: "الوقت",
  CONTACTUS: "اتصل بنا",
  CAR: "سيارة",
  BUS: "حافلة",
  TRAILOR: "تريلر",
  DELIVERYLOCATION: "عنوان نهاية الرحلة",
  SELECTTRIPS: "اختر الرحلات",
  SELECTHOURSPERDAY: "حدد الساعات في اليوم",
  LIGHTTRUCK: "شاحنة خفيفة",
  MINITRUCK: "شاحنة صغيرة",
  EXAVATOR: "حفارة",
  PICKUPLOCATION: "عنوان بداية الرحلة",
  BIDENDTIME: "وقت انتهاء المزايدة",
  BIDSTARTTIME: "وقت بدء المزايدة",
  SCHEDULETIME: "الجدول الزمني",
  SCHEDULERIDE: "وقت الرحلة",
  SELECTBRAND: "اختيار العلامة التجارية",
  PLEASESELECTVEHICLE: "الرجاء تحديد مركبة",
  SELECTVEHICLE: "اختر مركبة",
  SELECTDELIVERYLOCATION: "اختر عنوان نهاية الرحلة",
  SELECTPICKUPLOCATION: "اختر عنوان نهاية الرحلة",
  AUCTIONENDPOINT: "وقت انتهاء المزاد",
  PLEASESELECTCOMMODITY: "الرجاء تحديد السلع",
  SELECTCOMMODITY: "حدد السلعة",
  SELECTCATEGORY: "حدد الفئة",
  POSTENQUIRYY: "قدم طلبك",
  HR: "ساعة",
  PAYMENT: "الدفع",
  PAYMENTSUCCESSFULL: "تم الدفع بنجاح",
  PROCESSINGCOMPLETE: "اكتملت المعالجة",
  GOHOME: "اذهب للصفحة الرئيسية",
  PAYMENTFAILED: "فشل في عملية الدفع",
  REGESTRATIONFAILED: "فشل في التسجيل",
  LOGIBIDSDEPOSITAMOUT: "ايداع مبلغ للوجيبدز",
  HESABE: "حسابي",
  CREDITDEBITCARD: "بطاقة الائتمان / بطاقة الخصم",
  NETBANKING: "كى نت",
  PAY: "ادفع الأن",
  WALLETBALANCE: "رصيد المحفظة",
  WALLET: "محفظة",
  CHOOSEPAYMENTMETHOD: "اختر طريقة في الدفع",
  ADDMORE: "إضافة المال",
  WITHDRAW: "انسحب",
  YOURWALLETBALANCE: "رصيد محفظتك هو",
  ENTERAMOUNT: "أدخل المبلغ",
  TRIPDISTANCE: "مسافة الرحلة",
  CANCEL: "إلغاء",
  HOWWOULDYOURATETHEOVERALLQUALITYOFTRIP: "ما هو تقييمك الشامل لهذه الرحلة؟",
  NOTRIPSFOUND: "لم يتم العثور على رحلات",
  VIEW: "عرض",
  POSTENQUIRY: "قدم طلبك",
  MYENQUIRIES: "طلباتى",
  TRANSPORTPUBLIC: "النقل العام",
  TRANSPORTGOODS: "نقل البضائع",
  ENQUIRY: " طلب",
  COMMODITY: "حدد السلعة",
  TRIPS: "رحلات",
  RENTALOTHERS: "تأجير اخرى",
  ASSISTANCE: "مساعدة",
  INSURANCE: "تأمين",
  DRIVERRATING: "تقييم السائق:",
  SPRATING: 'تقييم مقدم الخدمة',
  BID: "مزايدة",
  LOWESTBID: "أدنى مزايدة",
  HIGHESTBID: "أعلى عرض",
  LOCATION: "موقعك",
  CONFIRMBID: "تأكيد المزايدة",
  ENQUIRYCHECKOUTPAGE: "صفحة مراجعة ودفع الطلب",
  DELETE: "حذف",
  CONFIRM: "تأكيد",
  PAYDIRECTLYTOSERVICEPROVIDER: "تأكيد",
  TOTALAMOUNT: "المبلغ الإجمالي",
  SERVICECHARGE: "رسوم خدمة لوجيبدز",
  WIDTH: "عرض",
  LENGTH: "الطول",
  HEIGHT: "ارتفاع",
  ENTERCODE: "ادخل الرمز",
  NONOTIFICATIONAVAILABLE: "لا توجد اشعارات متاحة",
  DELETEACCOUNT: "حذف الحساب",
  LOGOUT: "تسجيل خروج",
  MYWALLET: "محفظتي",
  MyTRIP: "رحلاتى",
  ENTERYOURPASSWORD: "أدخل كلمة مرور صالحة",
  LOGIN: "تسجيل الدخول",
  SETYOURLOCATION: "حدد موقعك",
  LANGUAGE: "لغة",
  GETSTARTED: "البدء",
  ENTERPHONENUMBER: "أدخل رقم هاتفك",
  ENTEROLDPASSWORD: "ادخل كلمة المرور القديمة",

  ENTERNEWPASSWORD: "تأكيد كلمة المرور",
  ENTERNEWPASSWORDMINLENGTH: "كلمة المرور الجديدة لا يمكن أن تكون أقل من 4 أحرف",

  ENTERCONFIRMPASSWORD: "تأكيد كلمة المرور",
  ENTERCODENEWPASSWORD: "أدخل الرمز الخاص بك وكلمة المرور الجديدة",

  FORGOTPASSWORD: "هل نسيت كلمة المرور؟",
  COPYRIGHT: "حقوق النشر © 2010-2020 (خدمة النقل)",
  NEXT: "التالي",
  DAYS: "يوم",
  HOURS: "ساعات",
  VEHICLEREQUIRED: "مطلوب مركبة",
  ADDBIDAMOUNT: "أضف مبلغ المزايدة",
  MINUTES: "دقائق",
  SUBMIT: "ارسال ",
  ALLBRAND: "كل العلامات التجارية",
  TRANSPORT: "النقل",
  RENTAL: "تأجير",
  ACCEPTTRIP: "قبول الرحلة",
  UPLOADCOMPANYLICENSE: "تحميل ترخيص الشركة",
  ACCEPTTERMSANDCONDITION: "قبول الشروط والأحكام",
  PICKUP: "يستلم",
  DOCUMENTS: "مستندات",
  UPLOADYOURID: "قم بتحميل رقمك ",
  DELIVER: "يسلم",
  REJECTTRIP: "رفض الرحلة",
  ADD: "إضافة",
  TRACKDRIVER: "تتبع السائق",
  BIDNOW: "زايد الآن",
  SHOWMAP: "عرض الخريطة",
  FROM: "من",
  TO: "الى",
  CALL: "اتصل",
  NAME: "الاسم",
  CONTACT: "رقم الاتصال",
  CONTRACT: "عقد",
  EMAIL: "بريد إلكتروني",
  CUSTOMERRATING: "تقييم العملاء",
  PRIVACYPOLICY: "سياسة الخصوصية",
  TERMSANDCONDITIONS: "الأحكام والشروط",
  BIDFOR: "مزايدة ب",
  YOU: "أنت",
  HOURSPERDAY: "ساعة في اليوم",
  NOOFTRIPS: "عدد الرحلات",
  TYPEOFVEHICLE: "نوع المركبة",
  GOTOTRIP: "اذهب إلى الرحلة",
  USERDETAILS: "بيانات المستخدم",
  CREATEBID: "إنشاء مزايدة",
  DRIVERDETAILS: "تفاصيل السائق",
  WEIGHTOFCOMMODITY: "وزن السلعة",
  DELIVERYCHARGE: "رسوم التوصيل",
  TOTALDISTANCE: "المسافة الكلية",
  PICKUPSCHEDULED: "الموعد المحدد",
  TYPEOFCOMMODITY: "نوع السلعة",
  REMAININGTIMEFORPICKUP: "الوقت المتبقي",
  ENQUIRYDETAILPAGE: "صفحة تفاصيل الطلب",
  HOME: "الرئيسية",
  ADDDRIVER: "إضافة سائق",
  ADDVEHICLE: "أضف مركبة",
  COMPLETEYOURPROFILE: "أكمل ملفك الشخصي",
  NOACTIVEBID: "لا يوجد مزايدة نشطة لك",
  YOURLASTBID: "مزايدتك الأخيرة",
  SEARCHENQUIRY: "بحث عن طلب",
  ENQUIRYENDS: "انتهى الطلب",
  NORECORDSFOUND: "لا توجد سجلات",
  AUCTIONS: "المزادات",
  MYENQUIRY: "طلباتي",
  DELIVERY: "توصيل",
  RUNNING: "جاري التنفيذ",
  PENDING: "قيد الانتظار",
  ENDED: "انتهى",
  CANCELED: "ألغيت",
  CANCELLED: "ألغيت",
  BIDDING_STARTED: "بدأ المزايدة",
  HALF_TIME: "أنتهت نصف المدة",
  ENDING_SOON: "ستنتهى قريبا",
  BIDS: "مزايدات",
  login_error_1: "الرجاء تقديم تفاصيل صحيحة!",
  Required: "*مطلوب",
  Invalid: "*غير صالحة",
  Invalid_PhnNumber: "* مطلوب رقم هاتف صالح",
  password_character: "* يجب أن تتكون كلمة المرور من 8 أحرف على الأقل ، حرف واحد صغير ، حرف كبير واحد ، رقم واحد ، حرف خاص واحد",
  driver_message: "هل أنت متأكد أنك تريد حذف السائق هذا؟",
  acpt_lbl: "نعم",
  rjct_lbl: "لا",
  passwword_must: "* الكلمة السريه يجب ان تتطابق",
  forgot_pwd_msg: "الرجاء إدخال رقم هاتف صحيح !",
  transport_good_detail: "هل أنت متأكد أنك تريد حذف هذا الاستفسار؟",
  account_message: "هل أنت متأكد أنك تريد حذف حسابك؟",
  bid_closed: "أغلقت المزايدة",
  enq_closed: "تم إغلاق االطلب!",
  enq_reject: "تم رفض الطلب!",
  signup_success: "تسجيل ناجح",
  confirm: "الرجاء الانتظار خمس ثوان للتحقق!",
  verification: "عملية التحقق ناجحة",
  otp: "تم إرسال OTP بنجاح",
  wrong: "هناك خطأ ما",
  upload: "رفع المستند",
  profile_updated: "تحديث الملف الشخصي !",
  pr_pwd_updated: "تم تحديث كلمة السر",
  enq_success: "تم اضافة الطلب بنجاح",
  enq_failed: "فشل إرسال الطلب",
  pay_success: "عملية دفع ناجحة",
  pay_error: "ادائیگی ناکام ہو گئی۔",
  enq_confirm: "تم تأكيد الطلب!",
  updated_pwd: "تم تحديث كلمة المرور بنجاح",
  oops: "عفوا",
  Driver: "سائق",
  OK: "حسنا",
  ACCEPTED: "تمت الموافقة",
  passwordstr1: "1) يجب أن تحتوي كلمة المرور الخاصة بك على 8 أحرف على الأقل.",
  passwordstr2: "2) يجب أن تحتوي كلمة مرورك على حرف واحد كبير أو كبير (على سبيل المثال: أ ، ب ، إلخ.)",
  passwordstr3: "3) يجب أن تحتوي كلمة مرورك على حرف صغير واحد على الأقل.",
  passwordstr4: "4) يجب أن تحتوي كلمة مرورك على رقم واحد على الأقل (على سبيل المثال: 0 ، 1 ، 2 ، 3 ، إلخ.)",
  passwordstr5: "5) يجب أن تحتوي كلمة مرورك على حرف خاص واحد على الأقل - على سبيل المثال: $، #، @،!،٪، ^، &، *، (،)",
  PLEASEWAITREDIRECTEDINFIVESECONDS: "من فضلك انتظر ستتم إعادة توجيه هذا خلال 5 ثواني",
  delete_bid: "تم حذف الاستفسار بنجاح",
  signIn: {
  phoneRequired: "الرجاء إدخال رقم الجوال",
  phoneValid: "الرجاء إدخال رقم جوال صالح",
  passwordRequired: "الرجاء إدخال كلمة المرور",
  passwordMinLength: "كلمة المرور لا يمكن أن تكون أقل من 4 أحرف",
  },
  password_must: "كلمة المرور يجب ان تتطابق",
  otp_required: "رمز التحقق مطلوب",
  editProfile: {
  email_invalid: "الرجاء إدخال البريد الإلكتروني الصحيح",
  },
  noti_label: "اشعارات",
  tripStatus: {
  readyMessage: "السائق جاهز للالتقاط.",
  startMessage: "بدأ السائق الرحلة.",
  endMessage: "أنهى السائق الرحلة.",
  },
  KMSDAYS: 'كم/يوم',
  FAQs: 'الأسئلة الشائعة'
  },
  ml: {
    KMSDAY: 'കിലോമീറ്റർ / ദിവസം',
    HRSDAY: 'മണിക്കൂർ / ദിവസം',
    NOTSTARTED: 'ആരംഭിച്ചിട്ടില്ല',
    MORE: 'കൂടുതൽ',
    CLIENT: 'ഉപഭോക്താവ്',
    BIDTIMEOVER: 'ബിഡ് സമയം കഴിഞ്ഞു',
    BRAND: 'ബ്രാൻഡ് തരം',
    MODEL: 'മോഡൽ',
    TIMEMESSAGE: 'ഡെലിവറി സമയ സന്ദേശം',
    TYPEOFDELIVERY: 'ഡെലിവറി തരം',
    IMMEDIATE: 'ഉടനടി',
    SCHEDULE: 'പട്ടിക',
    ImmediateDeliveryChoose: 'നിങ്ങളുടെ ഷെഡ്യൂൾ സമയം ഒരു മണിക്കൂറിൽ കുറവായതിനാൽ ഉടനടി ഡെലിവറി തിരഞ്ഞെടുക്കുക.',
    ADDRESSTYPE: 'വിലാസ ഇനം',
AREA: 'ഏരിയ',
APARTMENTNO: 'അപ്പാർട്ട്മെൻ്റ് നം.',
DELIVERAVENUE: 'ഡെലിവറി അവന്യൂ',
AVENUE: 'അവന്യൂ',
BUILDINGNO: 'കെട്ടിട നമ്പർ',
ADDITIONALINFO: 'അധിക വിവരങ്ങൾ',
RS: "റേറ്റിംഗ് വിജയിച്ചു",
SOMETHINGWENTWRONG: "ശ്ശോ! എന്തോ തെറ്റായി സംഭവിച്ചു",
LOADING: "ലോഡിംഗ്",
SEARCHIN: "തിരയുക",
YOURPHONENUMBER: "നിങ്ങളുടെ ഫോൺ നമ്പർ",
CHOOSEYOURLOCATION: "നിങ്ങളുടെ സ്ഥാനം തിരഞ്ഞെടുക്കുക",
DIDNOTRECIEVEOTP: "ഒ ടി പി  ലഭിച്ചില്ലേ?",
SIGNUPSUCCESFULL: "സൈൻ അപ്പ് വിജയിച്ചു",
PLEASEWAITREDIRECTEDINFIVESECONDS: "ദയവായി കാത്തിരിക്കൂ, ഇത് 5 സെക്കൻഡിനുള്ളിൽ റീഡയറക്‌ടുചെയ്യും",
TOTALKM: "ആകെ കി.മീ",
CONFIRMATION: "സ്ഥിരീകരണം",
INFOWINDOWCONTENT: "InfoWindow ഉള്ളടക്കം",
PICKUPSCHEDULED: "ഷെഡ്യൂൾ ചെയ്ത സമയം",
PLEASEPROVIDEAPPROPRIATEINFORMATION: "ദയവായി ആവശ്യമായ വിവരങ്ങൾ നൽകുക",
CHOOSEDIFFERENTDELIVERYLOCATION: "വ്യത്യസ്ത ഡെലിവറി ലൊക്കേഷൻ തിരഞ്ഞെടുക്കുക",
HOWWOULDYOURATETHEOVERALLQUALITYOFTRIP: "ഈ യാത്രയുടെ മൊത്തത്തിലുള്ള ഗുണനിലവാരം നിങ്ങൾ എങ്ങനെ വിലയിരുത്തും",
AUCTIONENDPOINT: "ലേലത്തിന്റെ അവസാന സമയം",
NOTRIPSFOUND: "യാത്രകളൊന്നും കണ്ടെത്തിയില്ല",
VIEW: "കാണുക",
STARTTIME: "ആരംഭിക്കുന്ന സമയം  ",
SELECTVEHICLE: "വാഹനം തിരഞ്ഞെടുക്കുക",
SELECTBRAND: "ബ്രാൻഡ് തിരഞ്ഞെടുക്കുക",
PLEASESELECTSUBCATEGORY: "ദയവായി ഉപവിഭാഗം തിരഞ്ഞെടുക്കുക",
SELECTVEHICLESPEC: 'മോഡൽ തിരഞ്ഞെടുക്കുക',
PLEASESELECTVEHICLESPEC: 'വാഹന മോഡൽ തിരഞ്ഞെടുക്കുക',
PLEASESELECTVEHICLE: "ദയവായി വാഹനം തിരഞ്ഞെടുക്കുക",
ENTERAMOUNT: "തുക നൽകുക",
POSTENQUIRYY: "അന്വേഷണം പോസ്റ്റ് ചെയ്യുക ",
POSTENQUIRY: "അന്വേഷണം പോസ്റ്റ് ചെയ്യുക",
MYENQUIRIES: "  എന്റെ അന്വേഷണങ്ങൾ",
TRANSPORTGOODS: "ചരക്ക് ഗതാഗതം ",
ENQUIRY: "അന്വേഷണങ്ങൾ",
PLEASEADDCOMMENT: "ദയവായി നിങ്ങളുടെ അഭിപ്രായം ചേർക്കുക",
REVIEW: "അവലോകനം",
PLEASEADDREVIEW: 'ദയവായി നിങ്ങളുടെ അവലോകനം ചേർക്കുക',
TRANSPORTPUBLIC: "പൊതു ഗതാഗതം ",
COMMODITY: "ചരക്കിന്റെ തരം",
TRIPS: "യാത്രകൾ ",
ASSISTANCE: "സഹായം",
INSURANCE: "ഇൻഷുറൻസ്",
DRIVERRATING: "ഡ്രൈവർ റേറ്റിംഗ്",
SPRATING: 'സേവന ദാതാവിൻ്റെ റേറ്റിംഗ്',
BID: "ലേലം",
LOWESTBID: "കുറഞ്ഞ ലേലം",
HIGHESTBID: " ഉയർന്ന ലേലം ",
LOCATION: "സ്ഥലം",
EDIT: "എഡിറ്റ് ചെയ്യുക ",
EDITPROFILE: "പ്രൊഫൈൽ എഡിറ്റ് ചെയ്യുക",
MYPROFILE: "എൻ്റെ പ്രൊഫൈൽ",
CONFIRMBID: " ലേലം സ്ഥിരീകരിക്കുക",
ENQUIRYCHECKOUTPAGE: "അന്വേഷണ ചെക്ക്ഔട്ട് പേജ്",
DELETE: "ഇല്ലാതാക്കുക",
CONFIRM: "ഉറപ്പുവരുത്തുക ",
ADDITIONALINFORMATION: "അധിക വിവരം",
PAYDIRECTLYTOSERVICEPROVIDER: "സേവന ദാതാവിന് നേരിട്ട് പണമടയ്ക്കുക",
TOTALAMOUNT: "മൊത്തം തുക",
SERVICECHARGE: "സേവന നിരക്ക്",
CHOOSEPAYMENTMETHOD: "നിങ്ങളുടെ പേയ്‌മെന്റ് രീതി തിരഞ്ഞെടുക്കുക",
MINUTES: "മിനിറ്റ്",
HR: "മണിക്കൂർ",
WIDTH: "വീതി",
PLEASESELECTCOMMODITY: "ദയവായി ചരക്ക് ഇനം  തിരഞ്ഞെടുക്കുക",
SELECTCOMMODITY: "ചരക്ക് ഇനം തിരഞ്ഞെടുക്കുക",
LENGTH: "നീളം",
HEIGHT: "ഉയരം",
ENTERCODE: "കോഡ് നൽകുക",
NONOTIFICATIONAVAILABLE: "അറിയിപ്പൊന്നും ലഭ്യമല്ല",
TERMSANDCONDITIONS: "ഉപാധികളും നിബന്ധനകളും",
DELETEACCOUNT: "അക്കൗണ്ട് ഇല്ലാതാക്കുക",
LOGOUT: "പുറത്തുകടക്കുക",
MYWALLET: "എൻ്റെ വാലറ്റ്",
MyTRIP: "എൻ്റെ യാത്ര",
ENTERYOURPASSWORD: "നിങ്ങളുടെ പാസ്സ്‌വേർഡ്‌ നൽകുക",
CONFIRMPASSWORD: "പാസ്സ്‌വേർഡ്‌ സ്ഥിരീകരിക്കുക",
newconfirmrequired: "ദയവായി പുതിയ പാസ്‌വേഡ് നൽകി പാസ്‌വേഡ് സ്ഥിരീകരിക്കുക",
oldconfirmrequired: "ദയവായി പഴയ പാസ്‌വേഡ് നൽകി പാസ്‌വേഡ് സ്ഥിരീകരിക്കുക",
oldnewPassword: "ദയവായി പഴയ പാസ്‌വേഡും പുതിയ പാസ്‌വേഡും നൽകുക",
OLDPASSWORDNEWPASSWORD: "പഴയ പാസ്‌വേഡും പുതിയ പാസ്‌വേഡും ഒന്നാകരുത്",
LOGIN: "ലോഗിൻ",
CONFIRMPICKUP: "പിക്കപ്പ് സ്ഥിരീകരിക്കുക",
SETYOURLOCATION: "നിങ്ങളുടെ ലൊക്കേഷൻ സജ്ജമാക്കുക",
LANGUAGE: "ഭാഷ ",
GETSTARTED: "തുടങ്ങി",
ENTERPHONENUMBER: "നിങ്ങളുടെ ഫോൺ നമ്പർ നൽകുക",
ENTEROLDPASSWORD: "പഴയ പാസ്സ്‌വേർഡ് നൽകുക",
ENTERCONFIRMPASSWORD: "പാസ്സ്‌വേർഡ് സ്ഥിരീകരിക്കുക",
ENTERNEWPASSWORD: "പുതിയ പാസ്സ്‌വേർഡ്‌ നൽകുക",
ENTERNEWPASSWORDMINLENGTH: "പുതിയ പാസ്സ്‌വേർഡ് 4 പ്രതീകങ്ങളിൽ കുറവായിരിക്കരുത്",
ENTERCODENEWPASSWORD: "നിങ്ങളുടെ കോഡും പുതിയ പാസ്സ്‌വേർഡ്ഡും നൽകുക",
FORGOTPASSWORD: "പാസ്സ്‌വേർഡ്‌ മറന്നോ?",
COPYRIGHT: "പകർപ്പവകാശം© 2010-2020 (ഗതാഗത സേവനം)",
NEXT: "അടുത്തത്",
WALLETBALANCE: "വാലറ്റ് ബാലൻസ്",
ALLBRAND: "എല്ലാ ബ്രാൻഡുകളും",
BIDNOW: "ഇപ്പോൾ ലേലം വിളിക്കുക",
TRANSPORT: "ഗതാഗതം",
VEHICLEREQUIRED: "വാഹനം ആവശ്യമാണ്",
RENTAL: "വാടക",
RENTALOTHERS: "വാടകയ്ക്ക് കൊടുക്കുന്ന മറ്റുള്ളവ",
WITHDRAW: "പിൻവലിക്കുക",
YOURWALLETBALANCE: "നിങ്ങളുടെ വാലറ്റ് ബാലൻസ് ആണ്",
DELIVERY: "ഡെലിവറി",
ADD: "ചേർക്കുക",
UPLOADYOURID: "നിങ്ങളുടെ ഐഡി അപ്‌ലോഡ് ചെയ്യുക",
NOOFTRIPS: "യാത്രകളുടെ എണ്ണം",
DOCUMENTS: "ഡോക്യൂമെന്റസ് ",
UPLOADCOMPANYLICENSE: "കമ്പനി ലൈസൻസ് അപ്‌ലോഡ് ചെയ്യുക",
ACCEPTTERMSANDCONDITION: "നിബന്ധനകളും വ്യവസ്ഥകളും അംഗീകരിക്കുന്നു",
SUBMIT: "സമർപ്പിക്കുക",
FUEL: "ഇന്ധനം",
REJECTTRIP: "യാത്ര നിരസിക്കുക",
PICKUP: "പിക്കപ്പ്",
DELIVER: "ഡെലിവറി",
CALL: "വിളി",
ACCEPTTRIP: "യാത്ര സ്വീകരിക്കുക",
SHOWMAP: "മാപ്പ് കാണിക്കുക",
DRIVER: "ഡ്രൈവർ",
SERVICE: "സർവീസ്",
DAYS: " ദിവസങ്ങൾ",
HOURSSLASHDAY: "മണിക്കൂർ/ദിവസം",
HOURS: "മണിക്കൂറുകൾ",
HOURSPERDAY: "പ്രതിദിനം മണിക്കൂറുകൾ",
SCHEDULETIME: "ഷെഡ്യൂൾ",
SCHEDULERIDE: "ഷെഡ്യൂൾ റൈഡ്",
BIDSTARTTIME: "ലേലം ആരംഭിക്കുന്ന സമയം",
BIDENDTIME: "ബിഡ് അവസാനിക്കുന്ന സമയം",
PICKUPLOCATION: "തുടക്ക ലൊക്കേഷൻ",
NOOFDAYS: "ദിവസങ്ങളുടെ എണ്ണം",
KMSPERDAY: "പ്രതിദിനം കി.മീ",
CREATEBID: "ലേലം സൃഷ്ടിക്കുക",
ENQUIRYENDS: "അന്വേഷണം അവസാനിക്കുന്നു",
PRIVACYPOLICY: "സ്വകാര്യതാ നയം",
NAME: "പേര്",
TO: "ടൂ ",
CONTACT: "ബന്ധപ്പെടുക",
EMAIL: "ഇമെയിൽ",
CUSTOMERRATING: "ഉപഭോക്തൃ റേറ്റിംഗ്",
BIDFOR: "ലേലം വിളിക്കുക ",
FROM: "ഫ്രം ",
YOU: "നിങ്ങൾ",
GOTOTRIP: "യാത്രയിലേക്ക് പോകുക",
USERDETAILS: "ഉപയോക്തൃ വിശദാംശങ്ങൾ",
DRIVERDETAILS: "ഡ്രൈവർ വിശദാംശങ്ങൾ",
WEIGHTOFCOMMODITY: "ചരക്കിന്റെ ഭാരം",
DELIVERYCHARGE: "ഡെലിവറി ചാർജ്",
TOTALDISTANCE: "മൊത്തം ദൂരം",
TRACKDRIVER: "ട്രാക്ക് ഡ്രൈവർ",
TYPEOFCOMMODITY: "ചരക്കിന്റെ തരം",
REMAININGTIMEFORPICKUP: "ശേഷിക്കുന്ന സമയം",
ADDBIDAMOUNT: "ബിഡ് തുക ചേർക്കുക",
ENQUIRYDETAILPAGE: "അന്വേഷണ വിശദാംശങ്ങളുടെ പേജ്",
TYPEOFVEHICLE: "വാഹനങ്ങളുടെ തരം",
HOME: "ഹോം",
ENQUIRYID: "അന്വേഷണ ഐഡി",
ADDDRIVER: "ഡ്രൈവറെ  ചേർക്കുക",
ADDVEHICLE: "വാഹനം  ചേർക്കുക",
COMPLETEYOURPROFILE: "നിങ്ങളുടെ പ്രൊഫൈൽ പൂർത്തിയാക്കുക",
NOACTIVEBID: "നിങ്ങൾക്ക്  ഒരു ലേലവും ലഭ്യമല്ല",
YOURLASTBID: "നിങ്ങളുടെ അവസാന ബിഡ്",
SEARCHENQUIRY: "അന്വേഷണം",
NORECORDSFOUND: "റെക്കോർഡുകൾ കണ്ടെത്തിയില്ല",
AUCTIONS: "ലേലങ്ങൾ",
RUNNING: "പ്രവർത്തിക്കുന്ന",
PENDING: "തീർച്ചപ്പെടുത്തിയിട്ടില്ല",
ENDED: "അവസാനിച്ചു",
CANCEL: "റദ്ദാക്കുക",
CANCELED: "റദ്ദാക്കി",
CANCELLED: "റദ്ദാക്കി",
CANCELLEDENQUIRY: "അന്വേഷണം റദ്ദാക്കി",
BIDDING_STARTED: "ലേലം ആരംഭിച്ചു",
HALF_TIME: "പകുതി സമയം കഴിഞ്ഞു",
ENDING_SOON: "പെട്ടെന്ന് അവസാനിക്കുന്നു",
BIDS: "ലേലങ്ങൾ",
login_error_1: "ദയവായി സാധുവായ വിശദാംശങ്ങൾ നൽകുക !",
Required: "*ആവശ്യമാണ്",
Invalid: "*അസാധുവാണ്",
Invalid_PhnNumber: "* സാധുവായ ഫോൺ നമ്പർ ആവശ്യമാണ്",
password_character: "* പാസ്സ്‌വേർഡ്‌ കുറഞ്ഞത് 8 പ്രതീകങ്ങൾ, 1 ചെറിയക്ഷരം, 1 വലിയക്ഷരം, 1 നമ്പർ, 1 പ്രത്യേക പ്രതീകങ്ങൾ എന്നിവ ആയിരിക്കണം",
driver_message: "ഈ ഡ്രൈവർ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
acpt_lbl: "അതെ",
rjct_lbl: "ഇല്ല",
passwword_must: "  * പാസ്സ്‌വേർഡ്  തുല്യമായിരിക്കണം",
forgot_pwd_msg: "ദയവായി സാധുവായ ഫോൺ നമ്പർ നൽകുക!",
transport_good_detail: "ഈ അന്വേഷണം ഇല്ലാതാക്കണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ?",
account_message: "നിങ്ങളുടെ അക്കൗണ്ട് ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
bid_closed: "ലേലം അവസാനിച്ചു ",
enq_closed: "അന്വേഷണം അവസാനിപ്പിച്ചു !",
enq_reject: "അന്വേഷണം നിരസിച്ചു!",
signup_success: "സൈൻഅപ്പ് വിജയിച്ചു",
confirm: "സ്ഥിരീകരണത്തിനായി ദയവായി 5 സെക്കൻഡ് കാത്തിരിക്കുക!",
verification: "പരിശോധിച്ചുറപ്പിക്കൽ വിജയിച്ചു",
otp: "ഒ  ടി പി ",
wrong: "കുഴപ്പം ",
upload: "പ്രമാണം അപ്‌ലോഡ് ചെയ്യുക",
profile_updated: "പ്രൊഫൈൽ അപ്ഡേറ്റ് ചെയ്തു !",
pr_pwd_updated: "പാസ്‌വേഡ് അപ്‌ഡേറ്റ് ചെയ്‌തു !",
enq_success: "അന്വേഷണം വിജയകരമായി പോസ്‌റ്റ് ചെയ്‌തു",
enq_failed: "അന്വേഷണം സമർപ്പിക്കുന്നതിൽ പരാജയപ്പെട്ടു",
pay_success: "പേയ്‌മെന്റ് വിജയിച്ചു",
pay_error: "പേയ്‌മെന്റ് പരാജയപ്പെട്ടു",
enq_confirm: "അന്വേഷണം സ്ഥിരീകരിച്ചിട്ടുണ്ട് !",
updated_pwd: "പാസ്‌വേഡ് വിജയകരമായി അപ്‌ഡേറ്റ് ചെയ്‌തു",
oops: "ഉപ്സ് ",
Driver: "ഡ്രൈവർ",
DELIVERYLOCATION: "ഡെലിവറി ലൊക്കേഷൻ",
OK: "ശരി",
ADDMORE: "പണം ചേർക്കുക",
TRIPDISTANCE: "യാത്രാ ദൂരം",
ACCEPTED: "അംഗീകരിച്ചു",
PAY: "പണം നൽകുക",
NETBANKING: "നെറ്റ് ബാങ്കിംഗ്",
CREDITDEBITCARD: "ക്രെഡിറ്റ് / ഡെബിറ്റ് കാർഡുകൾ",
WALLET: "വാലറ്റ്",
HESABE: "Hesabe",
PAYMENTFAILED: "പേയ്മെന്റ് പരാജയപ്പെട്ടു",
PROCESSINGCOMPLETE: "പ്രോസസ്സിംഗ് പൂർത്തിയായി",
GOHOME: "ഗോ ഹോം ",
PAYMENT: "പേയ്മെന്റ്  ",
REGESTRATIONFAILED: "രജിസ്ട്രേഷൻ പരാജയപ്പെട്ടു",
SCHEDULEDSTART: "ഷെഡ്യൂൾ ആരംഭം",
SELECTCATEGORY: "ഇനം തിരഞ്ഞെടുക്കുക",
LIGHTTRUCK: "ലൈറ്റ് ട്രക്ക്",
MINITRUCK: "മിനി ട്രക്ക്",
EXAVATOR: "എക്‌സ്‌കവേറ്റർ",
PAYMENTSUCCESFULL: "പേയ്‌മെന്റ് വിജയിച്ചു",
SELECTPICKUPLOCATION: "തുടക്ക ലൊക്കേഷൻ തിരഞ്ഞെടുക്കുക",
SELECTDELIVERYLOCATION: "അവസാന ലൊക്കേഷൻ തിരഞ്ഞെടുക്കുക",
SELECTHOURSPERDAY: "പ്രതിദിനം മണിക്കൂറുകൾ തിരഞ്ഞെടുക്കുക",
SELECTRIPS: "യാത്രകൾ തിരഞ്ഞെടുക്കുക",
CAR: "കാർ",
BUS: "ബസ്",
TRAILOR: "ട്രെയിലർ",
CONTACTUS: "ഞങ്ങളെ സമീപിക്കുക",
TIME: "സമയം",
USERTYPE: "ഉപയോക്തൃ തരം",
SIGNUP: "സൈൻ അപ്പ് ചെയ്യുക",
YOURACCOUNT: "നിങ്ങളുടെ അക്കൗണ്ട്",
DONTRECIEVE: "സ്വീകരിക്കരുത്",
AGREECONTINUE: "അംഗീകരിക്കുക & തുടരുക",
CHANGEPASSWORD: "പാസ്സ്‌വേർഡ് മാറ്റുക",
passwordstr10: "നിങ്ങളുടെ പാസ്‌വേഡിന് കുറഞ്ഞത് 8 പ്രതീകങ്ങളെങ്കിലും നീളം ഉണ്ടായിരിക്കണം, അതിൽ കുറഞ്ഞത് ഒരു വലിയക്ഷരം, ഒരു ചെറിയ അക്ഷരം, ഒരു നമ്പർ അക്കം, ഒരു പ്രത്യേക പ്രതീകം എന്നിവ ഉൾപ്പെടുന്നു.",
LOGIBIDSDEPOSITAMOUT: "ലോജിബിഡ്സ് നിക്ഷേപ തുക",
passwordstr1: "1) നിങ്ങളുടെ പാസ്സ്‌വേർഡിൽ കുറഞ്ഞത് 8 പ്രതീകങ്ങൾ അടങ്ങിയിരിക്കണം.",
passwordstr2: "2) നിങ്ങളുടെ പാസ്സ്‌വേർഡിൽ കുറഞ്ഞത് ഒരു വലിയക്ഷരമോ വലിയക്ഷരമോ ഉണ്ടായിരിക്കണം (ഉദാ: എ, ബി, മുതലായവ)",
passwordstr3: "3) നിങ്ങളുടെ പാസ്സ്‌വേർഡിൽ ഒരു ചെറിയ അക്ഷരമെങ്കിലും ഉണ്ടായിരിക്കണം.",
passwordstr4: "4) നിങ്ങളുടെ പാസ്സ്‌വേർഡിൽ ഒരു അക്കമെങ്കിലും ഉണ്ടായിരിക്കണം (ഉദാ: 0, 1, 2, 3, മുതലായവ)",
passwordstr5: "5) നിങ്ങളുടെ പാസ്സ്‌വേർഡിൽ കുറഞ്ഞത് ഒരു പ്രത്യേക പ്രതീകമെങ്കിലും അടങ്ങിയിരിക്കണം - ഉദാഹരണത്തിന്: $, #, @, !,%,^,&,*,(,)",
NOVEHICLEFOUND: "വാഹനങ്ങളൊന്നും കണ്ടെത്തിയില്ല",
RENTALS: "വാടകയ്ക്ക്",
YOURNAME: "നിങ്ങളുടെ പേര്",
YOUREMAIL: "നിങ്ങളുടെ ഇമെയിൽ (ഓപ്ഷണൽ)",
CHOOSEYOURSERVICES: "നിങ്ങളുടെ സേവനങ്ങൾ തിരഞ്ഞെടുക്കുക",
IMMEDIATEDELIVERY: "ഉടനടി ഡെലിവറി",
SCHEDULEDDELIVERY: "ഷെഡ്യൂൾ ചെയ്ത ഡെലിവറി",
APPARTMENTNUMBER: "അപ്പാർട്ട്മെന്റ് നമ്പർ",
BUILDINGNUMBER: "കെട്ടിട നമ്പർ",
STREET: "തെരുവ്",
BLOCK: "ബ്ലോക്ക് ",
STATE: "സംസ്ഥാനം",
PLEASESELECTADDRESSTYPE: "വിലാസ തരം തിരഞ്ഞെടുക്കുക",
SEARCHNEARESTLOCATION: "അടുത്തുള്ള ലൊക്കേഷൻ തിരയുക",
OLDPASSWORD: "പഴയ പാസ്സ്‌വേർഡ്",
NEWPASSWORD: "പുതിയ പാസ്സ്‌വേർഡ്",
YOUREMAILL: "നിങ്ങളുടെ ഇമെയിൽ",
LOGIBIDSONFACEBOOK: "ലോജിബിഡ്സ്  ഫേസ്ബുക്കിൽ  ",
LOGIBIDSONINSTAGRAM: "ലോജിബിഡ്സ്  ഇൻസ്റ്റാഗ്രാമിൽ",
LOGIBIDSONX: " ലോജിബിഡ്സ്  ട്വിറ്ററിൽ",
NORESULTSFOUND: "ഒരു ഫലവും കണ്ടെത്താനായില്ല",
ADDMONEY: "പണം ചേർക്കുക",
GOODS: "സാധനങ്ങൾ",
PUBLICTRANSPORT: "പൊതു ഗതാഗതം",
GOODSTRANSPORTRENTALS: "ചരക്ക് വാഹനങ്ങൾ",
HEAVYEQUIPEMENTS: "ഭാരമേറിയ ഉപകരണങ്ങൾ",
OTHERS: "മറ്റുള്ളവ",
PAIDFOR: "പണം നൽകി",
ADDEDMONEYTOWALLET: "വാലറ്റിലേക്ക് പണം ചേർത്തു",
CONTACTUSS: "ബന്ധപ്പെടുക",
SAVE: "സേവ് ചെയുക ",
HOURSS: "മണിക്കൂർ",
DAY: 'ദിവസം',
AIRPORT: "എയർപോർട്ട് ",
SELECTWEIGHT: "ഭാരം തിരഞ്ഞെടുക്കുക",
SCHEDULEDEND: "ഷെഡ്യൂൾ അവസാനം",
AUCTIONSTART: "ലേലം ആരംഭിക്കുന്നു ",
AUCTIONEND: "ലേലം അവസാനിക്കുന്നു ",
TRIPCOUNT: "യാത്രകളുടെ എണ്ണം",
AUCTIONENDTIME: "ലേലം അവസാനിക്കുന്ന സമയം ",
REQUIRED: "ആവശ്യമാണ്",
LOGIBIDSSERVICECHARGE: "ലോജിബിഡ്സ് സേവന നിരക്ക്",
delete_bid: "അന്വേഷണം ഇല്ലാതാക്കി",
withdraw_suff_amount_msg: "നിങ്ങളുടെ പക്കൽ മതിയായ തുകയില്ല",
auctionTime_error: "പ്രവർത്തനത്തിന്റെ ആരംഭ സമയവും അവസാനിക്കുന്ന സമയവും ഒരുപോലെ ആയിരിക്കരുത്",
signIn: {
  phoneRequired: "ദയവായി മൊബൈൽ നമ്പർ നൽകുക",
phoneValid: "ദയവായി സാധുവായ മൊബൈൽ നമ്പർ നൽകുക",
passwordRequired: "ദയവായി പാസ്സ്‌വേർഡ് നൽകുക",
passwordMinLength: "പാസ്സ്‌വേർഡ് 4 പ്രതീകങ്ങളിൽ കുറവായിരിക്കരുത്"
},
password_must: "രഹസ്യകോഡ് യോജിക്കണം",
otp_required: "ഒ  ടി പി  ആവശ്യമാണ്",
editProfile: {
email_invalid: "ദയവായി സാധുവായ ഇമെയിൽ ഐഡി നൽകുക",
},
noti_label: "അറിയിപ്പ്",
tripStatus: {
readyMessage: "ഡ്രൈവർ പിക്കപ്പിന് തയ്യാറാണ്.",
startMessage: "ഡ്രൈവർ യാത്ര തുടങ്ങി.",
endMessage: "ഡ്രൈവർ യാത്ര അവസാനിപ്പിച്ചു.",
},
KMSDAYS: 'കിമീ/ദിവസം',
FAQs: 'പതിവ് ചോദ്യങ്ങൾ'
  },
};
