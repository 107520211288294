import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {LoginResponse} from '../models/auth';
import {Notifications} from '../models/common';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthServicee {
  public fcmToken = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {
  }
  
  validateToken(token) {
    return this.http.post<LoginResponse>('validateToken', token)
      .pipe(map((data) => new LoginResponse(data)));
  }

  currentUserInfo() {
    return this.http.get<LoginResponse>('current-user')
      .pipe(map((data) => new LoginResponse(data)));
  }

  deleteAccount() {
    return this.http.delete('delete-user')
      .pipe(map((data) => data));
  }

  refreshToken() {
    const body = {refreshToken: localStorage.getItem('refreshToken'), idToken: localStorage.getItem('idToken')};
    return this.http.post<LoginResponse>('refresh', body)
      .pipe(map((data) => new LoginResponse(data)));
  }

  getUserNotifications(page) {
    return this.http.get(`notifications`, {params: {limit: '1506', page}})
      .pipe(map((data) => data));
  }

  viewedNotification() {
    return this.http.post<Notifications>(`read-notification`, {})
      .pipe(map((data) => data));
  }

  logout() {
    return this.http.post('logout', null);
}
}
